import getters from './get'
import actions from './action'
import mutations from './mutation'

const state = {
  load: false
}

export default {
  namepsaced: true,

  state,

  getters,
  actions,
  mutations
}
