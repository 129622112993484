<template>
  <Default>
    <section class="bg-content relative overflow-hidden z-0">
      <video autoplay muted loop ref="grid" id="grid" class="absolute inset-0 w-full h-full object-cover -z-1">
        <source src="@/asset/svg/grid.mp4" type="video/mp4">
      </video>
      <section class="container py-16 md:py-64 mx-auto flex items-center" style="background: no-repeat center/23% url('/asset/orb.svg');">
        <h3 class="w-full font-thriv text-center text-7xl md:text-11xl leading-section md:leading-hero text-white text-shadow transform -rotate-7 whitespace-pre-line">{{ content }}</h3>
      </section>
    </section>
    <section class="bg-content relative overflow-hidden z-0">
      <section class="container py-16 md:py-32 mx-auto">
        <h3 class="mx-4 md:mx-32 font-section text-xl md:text-5xl leading-none text-white whitespace-pre-line uppercase">{{ tag.hero }}</h3>
        <section class="mx-4 md:mx-48 py-4 text-white">
          <h5 class="py-4 md:text-xl">{{ tag.description }}</h5>
          <section class="text-xs md:text-sm space-y-3 text-justify" v-html="tag.content"></section>
          <h5 class="py-4 md:text-xl">{{ tag.cta }}</h5>
        </section>
        <section class="mx-4 md:mx-48 flex space-x-5">
          <CTA :to="{ hash: '#join' }" variant="bg-client text-xs md:text-sm text-white">let's talk</CTA>
          <!-- <CTA action="_" variant="bg-accent text-xs md:text-sm text-content">read more</CTA> -->
        </section>
      </section>
    </section>
    <section class="relative overflow-hidden z-0">
      <section class="container py-16 md:py-32 mx-auto">
        <h3 class="mx-4 md:mx-32 font-thriv text-7xl md:text-9xl leading-section md:leading-hero text-client text-shadow whitespace-pre-line">{{ section.hero }}</h3>
        <section class="mx-4 md:mx-auto flex flex-col items-center space-y-9">
          <section v-for="item in section.content" :key="item.tag" class="p-4 w-full md:w-80 bg-white border border-grey rounded-sm box-shadow md:even:mr-72 md:odd:ml-72"><h5 class="pb-4 font-section text-xl uppercase">{{ item.tag }}</h5><p class="text-xs" v-html="item.data"></p></section>
        </section>
        <section class="my-8 text-center">
          <CTA :to="{ path: '/', hash: '#join' }" variant="bg-accent text-xs md:text-sm text-content">apply</CTA>
        </section>
      </section>
    </section>
    <Apply />
  </Default>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Default from '@/layout/Default.vue'

import Hero from '@/component/Hero.vue'
import Apply from '@/component/Apply.vue'

export default defineComponent({
  meta: {
    title: `Home`,
    meta: [{ name: 'description', content: `content` }]
  },
  name: `Home`,
  components: {
    Default,

    Hero,
    Apply
  },
  mounted() {
    const grid:HTMLVideoElement | any = this.$refs.grid
    grid.playbackRate = 0.75
  },
  data() {
    return {
      content: `future
                of work`,
      tag: {
        hero: `welcome to
              the future`,
        description: `The career partner you've been looking for`,
        content: '<p class="">A profound shift of how software is built, maintained and delivered is underway - driven by individuals like you and collectives like us. Together we act as advocates to build current software projects with the best available talent regardless of affiliation, in modern environments away from a cubical and with motivation to innovate as a driver.</p><p class="">We’re here to help you find work that actually interests you, on your own terms. We’re not here to give you job listings, but to be an actual partner to you on your career.</p>',
        cta: `Interested?`
      },
      section: {
        hero: `our
              process`,
        content: [
          { tag: `initial commit`, data: 'tell us about yourself and<br />what matters to you.' },
          { tag: `review`, data: 'a human being will look at your<br />submission and be in touch.' },
          { tag: `peer programming`, data: 'we get together and discuss<br />your ambitions in person.' },
          { tag: `merge`, data: 'join our talent network and<br />become part of our community.' },
          { tag: `deploy`, data: 'we actively find project work that matches not<br />only your skill, but your ambition and values.' }
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
