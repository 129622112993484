
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: `ApplicationFooter`,
  data() {
    return {
      copyright: false,
      copyright_: '2022',
      address: `Vuorikatu 14A, 00100 Helsinki, Finland`,
      content: {
        privacy_policy: `Privacy Policy`
      },
      navigation: [
        { uuid: 1, to: '//futurice.com', name: 'futurice'},
        { uuid: 3, to: '//columbiaroad.com', name: 'columbia road'},
        { uuid: 5, to: '//meltlake.com', name: 'meltlake'}
      ],
      social: [
        { uuid: 1, to: '//instagram.com/thrivcompany', name: 'instagram'},
        { uuid: 3, to: '//twitter.com/thrivcompany', name: 'twitter'}
      ]
    }
  }
})
