import { createRouter, createWebHistory } from 'vue-router'

import Home from './Home/layout/Home.vue'
import NotFound from './Home/layout/NotFound.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/talent',
      name: 'talent',
      component: () => import(/* webpackChunkName: "talent" */ './Talent/layout/Talent.vue')
    },
    {
      path: '/client',
      name: 'client',
      component: () => import(/* webpackChunkName: "client" */ './Client/layout/Client.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './About/layout/About.vue')
    },
    {
      path: '/article',
      name: 'article',
      component: () => import(/* webpackChunkName: "article" */ './Article/layout/Article.vue')
    },
    { path: '/4oh4', name: '4oh4', component: NotFound },
    { path: '/:pathMatch(.*)', redirect: '4oh4' }
  ],

  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

export default router
