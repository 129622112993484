import { createStore } from 'vuex'

import home from './Home/store'

const data = {
  error: {
    404: {
      type: '',
      message: '',
      context: {
        path: ''
      }
    },
    500: {
      type: '',
      message: '',
      context: {
        path: ''
      }
    }
  },
  load: false,
  consent: false
}

export default createStore({
  state: {
    errorData: data.error[404],
    load: data.load,
    consent: data.consent
  },
  getters: {
    errorData: (state: StateObject) => state.errorData,
    load: (state: StateObject) => state.load,
    consent: (state: StateObject) => state.consent
  },
  actions: {
    ERROR: async ({ commit }: ActionStateObject, recieve: ErrorState) => {
      commit('ERROR', recieve)
    },
    LOAD: async ({ commit }: ActionStateObject, recieve: boolean) => {
      commit('LOAD', recieve)
    },
    CONSENT: async ({ commit }: ActionStateObject, recieve: boolean) => {
      commit('CONSENT', recieve)
    }
  },
  mutations: {
    ERROR: (state: StateObject, data: ErrorState) => {
      state.errorData.type = data.type
      state.errorData.message = data.message
    },
    LOAD: (state: StateObject, data: boolean) => {
      state.load = data
    },
    CONSENT: (state: StateObject, data: boolean) => {
      state.consent = data
    }
  },

  modules: {
    home
  }
})
