<template>
  <section id="join" class="relative z-0 bg-gradient-to-b from-client to-tertiary pb-24 md:pb-48">
    <section class="absolute -z-1 top-0 right-0 bottom-0 left-0" style="background: repeat-x bottom/.5% url('/asset/wave.svg');">
    </section>
    <section class="container py-8 px-4 md:py-16 md:px-0 mx-auto">
      <h5 class="font-section text-white text-3xl md:text-5xl uppercase">are you ready?</h5>
      <form class="relative md:grid md:grid-cols-2 md:gap-16 md:py-8 md:px-16" v-on:submit.prevent>
        <section class="space-y-10">
          <LabelInput required type="text" placeholder=" " name="firstname" label="Firstname*" v-model="localStore.firstname" :validate="localStore.hasError.firstname" />
          <LabelInput required type="text" placeholder=" " name="surname" label="Surname*" v-model="localStore.surname" :validate="localStore.hasError.surname" />
          <LabelInput required type="email" placeholder=" " name="email" label="eMail*" v-model="localStore.email" :validate="localStore.hasError.email" />

          <LabelInput required type="url" placeholder=" " name="url" label="LinkedIn URL" v-model="localStore.some" />

          <div class="relative my-2 border-b-2 border-content z-30">
            <select class="block w-full appearance-none focus:outline-none bg-transparent" name="available" v-model="localStore.available">
              <option disabled selected hidden value="">Available</option>
              <option>coming soon...</option>
              <option>let's do this!</option>
              <option>not sure yet.</option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </div>
            <!-- <label for="available" class="absolute top-0 duration-300 origin-0 text-content -z-1">Available</label> -->
          </div>
        </section>

        <section class="flex flex-col py-8 md:py-0 space-y-5">
          <p class="font-section text-content text-lg md:text-xl uppercase">i am a...</p>
          <section class="flex items-center justify-between">
            <Box label="developer">Developer</Box>
            <Box label="designer">Designer</Box>
            <Box label="other">Other</Box>
          </section>

          <section class="flex-grow space-y-2">
            <Switch v-model="localStore.consent" :validate="localStore.hasError.consent">I have read and agree to the <a class="link" href="//docs.google.com/document/d/1cDd3Dj3BmPtattyB3-Gxd_cR7-LwYAcMgtqeVIKn--c/edit?usp=sharing" target="_blank" rel="noreferrer">PrivacyPolicy</a></Switch>
            <Switch v-model="localStore.newsletter">I would love to receive the newsletter. No spam. Ever!</Switch>
          </section>

          <CTA :action="submit" variant="bg-black text-white">
            submit
          </CTA>
        </section>

        <section :class="sent ? 'flex' : 'hidden'" class="absolute top-0 right-0 bottom-0 left-0 mx-auto items-center justify-center bg-white box-shadow z-50">
          <h5 class="font-section text-client text-xl md:text-3xl uppercase">thank you for your interest</h5>
        </section>
      </form>
    </section>

    <Newsletter />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Newsletter from '@/component/Newsletter.vue'

export default defineComponent({
  name: `Apply`,
  components: {
    Newsletter
  },
  data() {
    return {
      sent: false,
      localStore: {
        firstname: '',
        surname: '',
        email: '',
        some: '',
        available: '',
        type: '',
        consent: false,
        newsletter: false,

        hasError: {
          firstname: false,
          surname: false,
          email: false,
          some: false,
          available: false,
          type: false,
          consent: false,
          newsletter: false
        }
      }
    }
  },
  methods: {
    reset(): any {
      this.setcommit()
      this.localStore.firstname = ''
      this.localStore.surname = ''
      this.localStore.email = ''
      this.localStore.some = ''
      this.localStore.available = ''
      this.localStore.type = ''

      this.localStore.consent = false
      this.localStore.newsletter = false

      this.$emit('update:modelValue')
    },

    setcommit(): any {
      this.localStore.hasError.firstname = false
      this.localStore.hasError.surname = false
      this.localStore.hasError.email = false

      this.localStore.hasError.consent = false
    },

    isvalid(): any {
      let error = false
      if(!this.localStore.firstname) {
        this.localStore.hasError.firstname = true
        error = true
      }

      if(!this.localStore.surname) {
        this.localStore.hasError.surname = true
        error = true
      }

      if(!this.localStore.email) {
        this.localStore.hasError.email = true
        error = true
      }



      if(!this.localStore.consent) {
        this.localStore.hasError.consent = true
        error = true
      }



      return error ? false : true
    },

    async submit() {
      this.setcommit()
      if(this.isvalid()) {
        const data = {
          fields: [
            { name: "firstname", value: this.localStore.firstname },
            { name: "lastname", value: this.localStore.surname },
            { name: "email", value: this.localStore.email },
            { name: "linkedin_url", value: this.localStore.some },
            { name: "available", value: this.localStore.available }
          ],
          context: {
            pageUri: `https://thriv.dev${this.$route.fullPath}`,
            pageName: this.$route.name
          },
          legalConsentOptions: {
            consent: {
              consentToProcess: this.localStore.consent,
              text: 'consent'
            }
          }
        }

        try {
          const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/8ec0445c-58a5-4d77-a1f6-8a9e20e2fe85', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if(response.status != 200) {
            throw new Error('error :/')
          }

          if(response.ok) {
            this.sent = true
            this.reset()
          }
        } catch(error) {
          console.log(error)
        }

        if(this.localStore.newsletter) {
          const newsletter_data = {
            fields: [
              {
                name: "email",
                value: this.localStore.email
              }
            ],
            context: {
              pageUri: `https://thriv.dev${this.$route.fullPath}`,
              pageName: this.$route.name
            }
          }

          try {
            const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/b9ac8d00-9b08-4aba-bbd9-e8bc8996e0e0', {
              body: JSON.stringify(newsletter_data),
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              }
            })

            if(response.status != 200) {
              throw new Error('error :/')
            }
          } catch(error) {
            console.log(error)
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
 