
import { defineComponent } from 'vue'

export default defineComponent({
  name: `LabelInput`,
  props: {
    type: String,
    required: Boolean,
    placeholder: String,

    name: String,

    label: String,

    varient: String,

    validate: Boolean,
    value: String
  }
})
