<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input :id="label" :name="label" type="checkbox" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
    </div>
    <div class="ml-1">
      <label :for="label" class="text-content">
        <slot />
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Box`,
  props: {
    label: String
  }
})
</script>

<style lang="scss" scoped>
</style>
