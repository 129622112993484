
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Switch`,
  props: {
    label: String,
    validate: Boolean
  },
  data() {
    return {
      set: false
    }
  },
  methods: {
    setting(): any {
      this.set = !this.set
      this.$emit('update:modelValue', this.set)
    }
  }
})
