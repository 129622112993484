<template>
  <section class="">
    <router-link v-if="!cta && !action && !external" :to="to" :class="variant" class="inline-flex py-2 px-12 font-section uppercase no-underline shadow">
      <slot />
    </router-link>

    <router-link v-if="cta" :to="to" :class="variant" class="inline-flex py-2 px-12 font-section uppercase no-underline shadow">
      <slot />
    </router-link>

    <button v-if="action" v-on:click="action" :class="variant" class="inline-flex py-2 px-12 font-section uppercase no-underline shadow">
      <slot />
    </button>

    <a v-if="external" :href="location" target="_blank" rel="noreferrer" :class="variant" class="inline-flex py-2 px-12 font-section uppercase no-underline shadow">
      <slot />
    </a>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `CTA`,
  props: {
    cta: Boolean,
    action: Function,
    to: Object,

    variant: String,

    external: Boolean,
    location: String
  }
})
</script>

<style lang="scss" scoped>
</style>
