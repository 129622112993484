<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  meta: {
    // layout will inject meta object
    titleTemplate(data: String) {
      return data ? `${data} | THRIV` : `THRIV`
    }
  },
  name: `Thriv`
})
</script>

<style lang="scss">
  @font-face {
    font-family: Thriv;
    src:  url("~@/asset/typography/HyperwaveOne.otf") format("opentype");
  }

  @font-face {
    font-family: ThrivSection;
    src:  url("~@/asset/typography/Video-Medium.otf") format("opentype");
  }

  @font-face {
    font-family: Load;
    src:  url("~@/asset/typography/BLOKKRegular.ttf") format("truetype")
          url("~@/asset/typography/BLOKKRegular.svg#BLOKK") format("svg");
  }

  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;300;400;500;700&family=IBM+Plex+Sans+Condensed:wght@100;300;400;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');
</style>
