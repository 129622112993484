import { createApp } from 'vue'
import Thriv from './Thriv.vue'

import router from './router'
import store from './store'
// import './registerServiceWorker'

import CTA from '@/component/common/CTA.vue'
import Inline from '@/component/common/Inline.vue'
import LabelInput from '@/component/common/LabelInput.vue'
import Switch from '@/component/common/Switch.vue'
import Box from '@/component/common/Box.vue'

import './asset/vendor/tail.css'

const application = createApp(Thriv)

application.component('CTA', CTA)
application.component('Inline', Inline)
application.component('LabelInput', LabelInput)
application.component('Switch', Switch)
application.component('Box', Box)

application.use(router)
application.use(store)

router.isReady().then(() => {
  application.mount(`#thriv`)
})
