<template>
  <section class="relative container py-4 px-4 md:py-8 md:px-8 mx-auto bg-white box-shadow">
    <h5 class="font-section text-client text-xl md:text-3xl uppercase">subscribe to our newsletter</h5>
    <p class="py-2 md:pr-72">
      Join our newsletter and receive a monthly digest of events, market insigths and just generally interesting stuff. No spam ever. Promise.
    </p>
    <form :class="sent ? 'hidden' : 'md:flex'" class="pt-8 md:mr-96 overflow-hidden" v-on:submit.prevent>
      <LabelInput class="flex-grow md:mr-8" required type="email" placeholder=" " name="email" label="eMail" varient=true v-model="localStore.email" :validate="localStore.hasError.email" />
      <CTA :action="submit" variant="bg-black text-white">
        subscribe
      </CTA>
    </form>

    <section :class="sent ? 'flex' : 'hidden'" class="mx-auto h-full items-center justify-center bg-white">
      <h5 class="font-section text-client text-xl md:text-3xl uppercase">thank you for your interest</h5>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Newsletter`,
  data() {
    return {
      sent: false,
      localStore: {
        email: '',

        hasError: {
          email: false
        }
      }
    }
  },
  methods: {
    reset(): any {
      this.setcommit()
      this.localStore.email = ''
    },

    setcommit(): any {
      this.localStore.hasError.email = false
    },

    isvalid(): any {
      let error = false
      if(!this.localStore.email) {
        this.localStore.hasError.email = true
        error = true
      }

      return error ? false : true
    },

    async submit() {
      this.setcommit()
      if(this.isvalid()) {
        const data = {
          fields: [
            {
              name: "email",
              value: this.localStore.email
            }
          ],
          context: {
            pageUri: `https://thriv.dev${this.$route.fullPath}`,
            pageName: this.$route.name
          }
        }

        try {
          const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/b9ac8d00-9b08-4aba-bbd9-e8bc8996e0e0', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if(response.status != 200) {
            throw new Error('error :/')
          }

          if(response.ok) {
            this.sent = true
            this.reset()
          }
        } catch(error) {
          console.log(error)
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
