<template>
  <section class="py-10 bg-content">
    <footer class="container mx-auto px-4 md:px-0 md:flex md:justify-between text-white">
      <section class="flex flex-col">
        <ul class="list-reset flex md:hidden self-center pb-10">
          <a :href="social[0].to" class="pr-1 hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">
            <span class="sr-only">{{ social[0].name }}</span>
            <svg class="h-7 w-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a :href="social[1].to" class="pl-1 hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">
            <span class="sr-only">{{ social[1].name }}</span>
            <svg class="h-7 w-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </ul>
        <router-link to="/">
          <Inline class="mb-3 w-20 fill-current" type="thriv" />
        </router-link>
        <section class="flex md:hidden flex-col">
          <ul class="list-reset flex flex-col">
            <li class="pb-3 font-thin text-sm no-underline"><p class="">{{ address }}</p></li>
            <li class="pt-3 pb-3 font-thin text-sm no-underline"><router-link to="/privacy" class="hover:text-client transition-all ease-in-out duration-300" active-class="text-client">{{ content.privacy_policy }}</router-link></li>
          </ul>
        </section>
        <section class="hidden mt-3 md:flex items-center">
          <Inline class="w-16 fill-current" type="futurice_family" />
          <ul class="list-reset flex flex-col">
            <li v-for="item in navigation" :key="item.uuid" class="pl-3 font-thin text-sm capitalize no-underline"><a :href="item.to" class="hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">{{ item.name }}</a></li>
          </ul>
        </section>
      </section>
      <section class="flex mt-3 md:hidden items-center justify-between">
        <ul class="list-reset flex flex-col">
          <li v-for="item in navigation" :key="item.uuid" class="font-thin text-sm capitalize no-underline"><a :href="item.to" class="hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">{{ item.name }}</a></li>
        </ul>
        <Inline class="w-16 fill-current" type="futurice_family" />
      </section>
      <section class="hidden md:flex flex-col">
        <ul class="list-reset flex pb-3">
          <a :href="social[0].to" class="pr-1 hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">
            <span class="sr-only">{{ social[0].name }}</span>
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a :href="social[1].to" class="pl-1 hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">
            <span class="sr-only">{{ social[1].name }}</span>
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </ul>
        <ul class="list-reset flex flex-col pt-3">
          <li class="pb-3 font-thin text-sm no-underline"><a href="//docs.google.com/document/d/1cDd3Dj3BmPtattyB3-Gxd_cR7-LwYAcMgtqeVIKn--c/edit?usp=sharing" target="_blank" rel="noreferrer" class="hover:text-client transition-all ease-in-out duration-300" active-class="text-client">{{ content.privacy_policy }}</a></li>
          <li class="pt-3 font-medium text-sm no-underline"><p class="">Thriv Oy</p></li>
          <li class="font-thin text-sm no-underline"><p class="">{{ address }}</p></li>
        </ul>
      </section>
    </footer>
    <section v-if="copyright" class="pt-10 container mx-auto flex flex-col items-center text-grey">
      <p class="font-thin text-xs uppercase">made with <span class="text-tertiary">&lt;3</span> in helsinki</p>
      <p class="font-thin text-tiny uppercase">copyright thriv {{ copyright_ }}</p>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: `ApplicationFooter`,
  data() {
    return {
      copyright: false,
      copyright_: '2022',
      address: `Vuorikatu 14A, 00100 Helsinki, Finland`,
      content: {
        privacy_policy: `Privacy Policy`
      },
      navigation: [
        { uuid: 1, to: '//futurice.com', name: 'futurice'},
        { uuid: 3, to: '//columbiaroad.com', name: 'columbia road'},
        { uuid: 5, to: '//meltlake.com', name: 'meltlake'}
      ],
      social: [
        { uuid: 1, to: '//instagram.com/thrivcompany', name: 'instagram'},
        { uuid: 3, to: '//twitter.com/thrivcompany', name: 'twitter'}
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
