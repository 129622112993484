
import { defineComponent } from 'vue'

import Newsletter from '@/component/Newsletter.vue'

export default defineComponent({
  name: `Apply`,
  components: {
    Newsletter
  },
  data() {
    return {
      sent: false,
      localStore: {
        firstname: '',
        surname: '',
        email: '',
        some: '',
        available: '',
        type: '',
        consent: false,
        newsletter: false,

        hasError: {
          firstname: false,
          surname: false,
          email: false,
          some: false,
          available: false,
          type: false,
          consent: false,
          newsletter: false
        }
      }
    }
  },
  methods: {
    reset(): any {
      this.setcommit()
      this.localStore.firstname = ''
      this.localStore.surname = ''
      this.localStore.email = ''
      this.localStore.some = ''
      this.localStore.available = ''
      this.localStore.type = ''

      this.localStore.consent = false
      this.localStore.newsletter = false

      this.$emit('update:modelValue')
    },

    setcommit(): any {
      this.localStore.hasError.firstname = false
      this.localStore.hasError.surname = false
      this.localStore.hasError.email = false

      this.localStore.hasError.consent = false
    },

    isvalid(): any {
      let error = false
      if(!this.localStore.firstname) {
        this.localStore.hasError.firstname = true
        error = true
      }

      if(!this.localStore.surname) {
        this.localStore.hasError.surname = true
        error = true
      }

      if(!this.localStore.email) {
        this.localStore.hasError.email = true
        error = true
      }



      if(!this.localStore.consent) {
        this.localStore.hasError.consent = true
        error = true
      }



      return error ? false : true
    },

    async submit() {
      this.setcommit()
      if(this.isvalid()) {
        const data = {
          fields: [
            { name: "firstname", value: this.localStore.firstname },
            { name: "lastname", value: this.localStore.surname },
            { name: "email", value: this.localStore.email },
            { name: "linkedin_url", value: this.localStore.some },
            { name: "available", value: this.localStore.available }
          ],
          context: {
            pageUri: `https://thriv.dev${this.$route.fullPath}`,
            pageName: this.$route.name
          },
          legalConsentOptions: {
            consent: {
              consentToProcess: this.localStore.consent,
              text: 'consent'
            }
          }
        }

        try {
          const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/8ec0445c-58a5-4d77-a1f6-8a9e20e2fe85', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if(response.status != 200) {
            throw new Error('error :/')
          }

          if(response.ok) {
            this.sent = true
            this.reset()
          }
        } catch(error) {
          console.log(error)
        }

        if(this.localStore.newsletter) {
          const newsletter_data = {
            fields: [
              {
                name: "email",
                value: this.localStore.email
              }
            ],
            context: {
              pageUri: `https://thriv.dev${this.$route.fullPath}`,
              pageName: this.$route.name
            }
          }

          try {
            const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/b9ac8d00-9b08-4aba-bbd9-e8bc8996e0e0', {
              body: JSON.stringify(newsletter_data),
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              }
            })

            if(response.status != 200) {
              throw new Error('error :/')
            }
          } catch(error) {
            console.log(error)
          }
        }
      }
    }
  }
})
