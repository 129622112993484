<template>
  <div :class="varient ? 'labelinput labelinput-alt focus-within:border-client' : 'labelinput focus-within:border-white'" class="relative my-2 border-b-2 border-content z-30">
    <input class="block w-full appearance-none focus:outline-none bg-transparent" :type="type" :name="name" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)" />
    <label :for="name" :class="validate ? 'text-error' : 'text-content' " class="absolute flex w-full top-0 duration-300 origin-0 -z-1">{{ label }}<span v-if="validate" class="inline-flex flex-grow justify-end items-end text-error text-xs italic uppercase">   required</span></label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `LabelInput`,
  props: {
    type: String,
    required: Boolean,
    placeholder: String,

    name: String,

    label: String,

    varient: String,

    validate: Boolean,
    value: String
  }
})
</script>

<style lang="scss" scoped>
</style>
