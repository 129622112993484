
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Default from '@/layout/Default.vue'

import Hero from '@/component/Hero.vue'
import Apply from '@/component/Apply.vue'

export default defineComponent({
  meta: {
    title: `Home`,
    meta: [{ name: 'description', content: `content` }]
  },
  name: `Home`,
  components: {
    Default,

    Hero,
    Apply
  },
  mounted() {
    const grid:HTMLVideoElement | any = this.$refs.grid
    grid.playbackRate = 0.75
  },
  data() {
    return {
      content: `future
                of work`,
      tag: {
        hero: `welcome to
              the future`,
        description: `The career partner you've been looking for`,
        content: '<p class="">A profound shift of how software is built, maintained and delivered is underway - driven by individuals like you and collectives like us. Together we act as advocates to build current software projects with the best available talent regardless of affiliation, in modern environments away from a cubical and with motivation to innovate as a driver.</p><p class="">We’re here to help you find work that actually interests you, on your own terms. We’re not here to give you job listings, but to be an actual partner to you on your career.</p>',
        cta: `Interested?`
      },
      section: {
        hero: `our
              process`,
        content: [
          { tag: `initial commit`, data: 'tell us about yourself and<br />what matters to you.' },
          { tag: `review`, data: 'a human being will look at your<br />submission and be in touch.' },
          { tag: `peer programming`, data: 'we get together and discuss<br />your ambitions in person.' },
          { tag: `merge`, data: 'join our talent network and<br />become part of our community.' },
          { tag: `deploy`, data: 'we actively find project work that matches not<br />only your skill, but your ambition and values.' }
        ]
      }
    }
  }
})
