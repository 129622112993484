
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  meta: {
    // layout will inject meta object
    titleTemplate(data: String) {
      return data ? `${data} | THRIV` : `THRIV`
    }
  },
  name: `Thriv`
})
