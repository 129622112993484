
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Application from '@/layout/common/Head.vue'
import ApplicationFooter from '@/layout/common/Footer.vue'

export default defineComponent({
  name: `Default`,
  components: {
    Application,
    ApplicationFooter
  },
  computed: {
    ...mapState({
      state: (state: any) => state
    }),
    ...mapGetters({
      errorData: 'errorData',
      load: 'load',
      consent: 'consent'
    })
  }
})
