
import { defineComponent } from 'vue'

export default defineComponent({
  name: `CTA`,
  props: {
    cta: Boolean,
    action: Function,
    to: Object,

    variant: String,

    external: Boolean,
    location: String
  }
})
