<template>
  <div class="" v-html="require(`!html-loader!@/asset/svg/${type}.svg`)"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Inline`,
  props: {
    type: String
  }
})
</script>

<style lang="scss" scoped>
</style>
