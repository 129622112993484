<template>
  <section class="bg-content">
    <section class="container py-32 mx-auto flex items-center">
      <h3 class="w-full font-thriv text-center text-9xl leading-hero text-white text-shadow transform -rotate-7 whitespace-pre-line">{{ content }}</h3>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Hero`,
  props: {
    content: String
  }
})
</script>

<style lang="scss" scoped>
</style>
