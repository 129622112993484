
import { defineComponent } from 'vue'

export default defineComponent({
  name: `Newsletter`,
  data() {
    return {
      sent: false,
      localStore: {
        email: '',

        hasError: {
          email: false
        }
      }
    }
  },
  methods: {
    reset(): any {
      this.setcommit()
      this.localStore.email = ''
    },

    setcommit(): any {
      this.localStore.hasError.email = false
    },

    isvalid(): any {
      let error = false
      if(!this.localStore.email) {
        this.localStore.hasError.email = true
        error = true
      }

      return error ? false : true
    },

    async submit() {
      this.setcommit()
      if(this.isvalid()) {
        const data = {
          fields: [
            {
              name: "email",
              value: this.localStore.email
            }
          ],
          context: {
            pageUri: `https://thriv.dev${this.$route.fullPath}`,
            pageName: this.$route.name
          }
        }

        try {
          const response:any = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/8952114/b9ac8d00-9b08-4aba-bbd9-e8bc8996e0e0', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if(response.status != 200) {
            throw new Error('error :/')
          }

          if(response.ok) {
            this.sent = true
            this.reset()
          }
        } catch(error) {
          console.log(error)
        }
      }
    }
  }
})
